import logo from "./logo.svg";
import "./App.css";

import queryString from "query-string";
import Main from "./Main";
function App() {
  let params = queryString.parse(document.location.search);

  return (
    <div className="App">
      <Main orderId={params.orderId} />
    </div>
  );
}

export default App;
