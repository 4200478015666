import React, { useEffect, useState } from "react";
import httpClient from "./httpClient";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import numeral from "numeral";
import { StatusMessage } from "./status-message";

const OrderDetails = (props) => {
  const { Order } = props;
  console.log(Order);
  return (
    <div
      className={"ticket-bg"}
      style={{
        backgroundColor: "#fff",
        borderRadius: 10,
        position: "absolute",
        top: 250,
        flex: 1,
        alignSelf: "center",

        display: "flex",
        flexDirection: "column",
        padding: 20,
        paddingLeft: 40,
        paddingRight: 40,
        alignItems: "flex-start",
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        justifyContent: "center",
      }}
    >
      {/* <div
              style={{
                width: "100%",
                borderRadius: 5,
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            > */}

      <span
        style={{
          fontSize: 30,
        }}
      >
        {Order.OrderNumber ? "#" + Order.OrderNumber : <Skeleton width={60} />}
      </span>
      <StatusMessage order={Order} />
      {/* <h2
              style={{
                marginBottom: 0,
                marginTop: -10,
              }}
            >
              {Order.Name}
            </h2> */}
      {/* </div> */}
      {Order.Notes != undefined ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: 20,
          }}
        >
          <p>Notes: {Order.Notes}</p>
          <p>Dinners: {Order.Dinners}</p>
          <p>Tip: {Order.MoneySign + Order.Tip}</p>
          {Order.Delivery && <p>{Order.DeliveryInstructions}</p>}
          {Order.Delivery && <p>Delivery Fee:{Order.DeliveryFee}</p>}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            textAlign: "left",
            width: "100%",
            marginTop: 20,
          }}
        >
          <Skeleton count={1} width={300} />
          <Skeleton count={1} width={250} />
          <Skeleton count={1} width={280} />
        </div>
      )}
      <div
        style={{
          position: "absolute",
          top: 5,
          alignSelf: "center",
        }}
      >
        {<OrderStatus {...{ Order }} />}
      </div>

      {Order.Items.length == 0 && (
        <div style={{ width: "100%" }}>
          <Skeleton style={{ marginTop: 20 }} />
          <Skeleton height={100} />

          <Skeleton style={{ marginTop: 20 }} />
          <Skeleton height={100} />

          <Skeleton style={{ marginTop: 20 }} />
          <Skeleton height={100} />
        </div>
      )}
      {Order.Items.map((item, key) => {
        return (
          <div
            key={key}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: 20,
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  //justifyContent: "space-around",
                  borderBottom: "1px solid #333",
                  fontSize: 20,
                }}
              >
                <span
                  style={{
                    fontWeight: "800",
                    marginLeft: 20,
                    flex: 5,
                    display: "flex",
                    alignItems: "flex-start",
                    marginRight: 20,
                  }}
                >
                  {item.Name}
                </span>
                <span
                  style={{
                    flex: 1,
                    display: "flex",
                  }}
                >
                  {item.Quantity}
                </span>
                <span
                  style={{
                    fontWeight: "800",
                    flex: 1,
                    display: "flex",
                  }}
                >
                  {Order.MoneySign}
                  {numeral(item.Price).format("0,0.00")}
                </span>
              </div>
              <span>{item.Notes}</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#eaeaea",
              }}
            >
              <ul
                style={{
                  textAlign: "left",
                  //backgroundColor: "pink",
                  flex: 1,
                  listStyle: "none",
                  paddingInlineStart: 0,
                }}
              >
                {item.Toppings.map((topping, keyTop) => {
                  return (
                    <li key={keyTop}>
                      <span
                        style={{
                          fontWeight: "800",
                          marginLeft: 10,
                          marginRight: 10,
                        }}
                      >
                        {topping.Name}
                      </span>
                      <span>
                        {Order.MoneySign}
                        {topping.PriceChange}
                      </span>
                    </li>
                  );
                })}
                {item.Optionals.map((topping, keyOp) => {
                  return (
                    <li key={20 + keyOp}>
                      <span
                        style={{
                          fontWeight: "800",
                          marginLeft: 10,
                          marginRight: 10,
                        }}
                      >
                        {topping.OptionName}
                      </span>
                      <span
                        style={{
                          marginLeft: 10,
                          marginRight: 10,
                          justifyContent: "space-around",
                        }}
                      >
                        {topping.Value}
                      </span>
                      <span>
                        {Order.MoneySign}
                        {topping.PriceChange}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
            {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
          </div>
        );
      })}
      <h3
        style={{
          top: 5,
          alignSelf: "flex-end",
          marginBottom: 0,
          fontWeight: "300",
        }}
      >
        {Order.Tax ? (
          "Tax: " +
          Order.MoneySign +
          numeral(Order.Tax * Order.Total).format("0,0.00")
        ) : (
          <Skeleton width={60} />
        )}
      </h3>
      <h2
        style={{
          top: 5,
          marginTop: 10,
          alignSelf: "flex-end",
          right: 30,
          marginBottom: 10,
        }}
      >
        {Order.Total ? (
          "Total: " + Order.MoneySign + numeral(Order.Total).format("0,0.00")
        ) : (
          <Skeleton width={60} />
        )}
      </h2>
      {Order.RestaurantPhone && (
        <h2>
          Questions? Call:{" "}
          <a href={"tel:" + Order.RestaurantPhone}>{Order.RestaurantPhone}</a>
        </h2>
      )}
    </div>
  );
};

const NoOrderDetails = (props) => {
  const { Order } = props;
  return (
    <div
      className={"ticket-bg"}
      style={{
        backgroundColor: "#fff",
        borderRadius: 10,
        position: "absolute",
        top: 250,
        flex: 1,
        alignSelf: "center",

        display: "flex",
        flexDirection: "column",
        padding: 80,
        alignItems: "center",
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        justifyContent: "center",
      }}
    >
      <h2>Not Found / לא נמצא</h2>
      <img
        src="/images/undraw_feeling_blue_4b7q.svg"
        style={{
          width: "80%",
        }}
      />
    </div>
  );
};

const OrderStatus = (props) => {
  const { Order } = props;

  const getColor = (status) => {
    switch (Order.Status) {
      case 0:
        return "#d4bd09";
      case 1:
        return "orange";
      case 2:
        return "green";

      default:
        break;
    }
  };

  return Order.Status != undefined ? (
    <div
      style={{
        backgroundColor: getColor(Order.Status),
        height: 40,
        width: 80,
        border: "1px solid #333",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 15,
        borderRadius: 20,
        color: "white",
        fontSize: 18,
      }}
    >
      {Order.Delivery ? "Delivery" : "Takeout"}
    </div>
  ) : (
    <Skeleton
      style={{
        marginTop: 15,
        borderRadius: 20,
      }}
      width={80}
      height={40}
      circle={false}
    />
  );
};

const Main = (props) => {
  const [Order, setOrder] = useState({
    Items: [],
  });

  const [NoOrder, setNoOrder] = useState(false);
  const lang = "en";
  useEffect(() => {
    httpClient
      .spost("/ws/Menu.asmx/GetTakeOutOrderByID", {
        OrderID: props.orderId,
      })
      .then(({ data }) => {
        console.log("gotOrder", data);

        const orderMap = {};
        let orderID = "";
        //console.log("typeof data", typeof data);
        if (!data.length) {
          setNoOrder(true);
          return;
        }
        data.forEach((order) => {
          orderMap[order.ID] || (orderMap[order.ID] = { Items: [] });
          orderID = order.ID;
          orderMap[order.ID].Name = order.Name;
          orderMap[order.ID].ID = order.ID;
          orderMap[order.ID].TakeoutOrderID = order.TakeoutOrderID;
          orderMap[order.ID].CC = order.CC;
          orderMap[order.ID].CCV = order.CCV;
          orderMap[order.ID].Status = order.Status;
          orderMap[order.ID].Exp = order.Exp;
          orderMap[order.ID].RestaurantName = order.RestaurantName;
          orderMap[order.ID].RestaurantPhone = order.RestaurantPhone;
          orderMap[order.ID].Category = order.Category;
          orderMap[order.ID].Notes = order.Notes;
          orderMap[order.ID].Phone = order.Phone;
          orderMap[order.ID].Takeout = order.Takeout;
          orderMap[order.ID].Delivery = order.Delivery;
          orderMap[order.ID].Address = order.Address;
          orderMap[order.ID].HomeNumber = order.HomeNumber;
          orderMap[order.ID].Dinners = order.Dinners;
          orderMap[order.ID].City = order.City;
          orderMap[order.ID].DeliveryFee = order.DeliveryFee;
          orderMap[order.ID].ProcessedBy = order.ProcessedBy;
          orderMap[order.ID].CompletedBy = order.CompletedBy;
          orderMap[order.ID].ConfirmedBy = order.ConfirmedBy;
          orderMap[order.ID].PrintedBy = order.PrintedBy;
          orderMap[order.ID].Curbside = order.Curbside;
          orderMap[order.ID].CanceledBy = order.CanceledBy;
          orderMap[order.ID].CompletedTime = order.CompletedTime;
          orderMap[order.ID].PickedUpTime = order.PickedUpTime;
          orderMap[order.ID].Total = order.Total;
          orderMap[order.ID].TimeEstimation = order.TimeEstimation;
          orderMap[order.ID].Tip = order.Tip;
          orderMap[order.ID].ZIP = order.ZIP;
          orderMap[order.ID].DeliveryInstructions = order.DeliveryInstructions;
          orderMap[order.ID].Canceled = order.Canceled;
          orderMap[order.ID].LocationID = order.LocationID;
          orderMap[order.ID].MoneySign = order.MoneySign;
          orderMap[order.ID].RestaurantPhone = order.RestaurantPhone;
          orderMap[order.ID].CreatedDate = order.CreatedDate;
          orderMap[order.ID].Cash = order.Cash;
          orderMap[order.ID].PickedUp = order.PickedUp;
          orderMap[order.ID].Color = order.Color;
          orderMap[order.ID].TableID = order.TableID;
          orderMap[order.ID].Tax = order.Tax;
          orderMap[order.ID].OrderNumber = order.OrderNumber;
          orderMap[order.ID].Lang = order.Lang;

          let item = Object.assign({}, order);
          Object.keys(orderMap[orderID]).forEach((key) => {
            if (key != "ID") delete item[key];
          });
          orderMap[order.ID].Items.push(item.Item);
        });
        setOrder(orderMap[orderID]);
      })
      .catch(() => {
        setNoOrder(true);
      });
  }, []);

  return (
    <div>
      <SkeletonTheme>
        <div
          style={{
            position: "absolute",
            width: "100%",
            // height: 152,
            overflow: "hidden",
          }}
        >
          <img
            id="header_bg"
            style={{ width: "100%" }}
            src={
              httpClient.defaults.baseURL +
              "/firstpageimages/" +
              Order.LocationID +
              ".jpg"
            }
          ></img>
        </div>
        <svg className="MHeader_bg" viewBox="0 0 418 238">
          <linearGradient
            id="header_gd"
            spreadMethod="pad"
            x1="0.5"
            x2="0.5"
            y1="0"
            y2="0.805"
          >
            <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
            <stop offset="1" stopColor="#000" stopOpacity="0"></stop>
          </linearGradient>
          <path
            fill="url(#header_gd)"
            d="M 0 0 L 418 0 L 418 185 L 418 238 L 0 238 L 0 0 Z"
          ></path>
        </svg>
        <div style={{ position: "absolute", top: 18, width: "100%" }}>
          <span
            className="MHeader_LocationName"
            style={{ color: Order.ColorText }}
          >
            {Order.RestaurantName || <Skeleton width={200} />}
          </span>
        </div>
        <div
          style={{
            width: "100%",
            backgroundColor: Order.color,
            height: 8,
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            height: 128,
            width: "100%",
            top: 89,
          }}
        >
          <table
            border="0"
            style={{ width: "100%", borderWidth: 1, borderColor: "black" }}
          >
            <tbody>
              <tr>
                <td></td>
                <td style={{ width: 128, position: "relative" }}>
                  <div
                    style={{
                      backgroundColor: "white",
                      width: 128,
                      height: 128,
                      borderRadius: 64,
                      position: "absolute",
                      boxShadow: "0px 0px 3px #CCC",
                    }}
                  >
                    {Order.LocationID ? (
                      <img
                        style={{
                          padding: 0,
                          width: 128,
                          height: 128,
                          borderRadius: 64,
                          objectFit: "contain",
                          objectPosition: "center",
                        }}
                        src={
                          httpClient.defaults.baseURL +
                          "/logos/" +
                          Order.LocationID +
                          ".jpg"
                        }
                      />
                    ) : (
                      <Skeleton circle={true} height={128} width={128} />
                    )}
                  </div>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          {!NoOrder ? (
            <OrderDetails {...{ Order }} />
          ) : (
            <NoOrderDetails {...{ Order }} />
          )}
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default Main;
