import { css } from '@emotion/css'
import moment from 'moment'

const style = {
  container: css`
    margin-top: 2rem;
    border: 1px solid black;
    width: 100%;
    border-radius: 0.5rem;
  `
}

const MENUAL_URL = 'https://menual.azurewebsites.net'
// TODO: internationalization?

function Pending () {
  return (
    <p>The order is pending approval</p>
  )
}

function Cancelled () {
  return (
    <p>The order is cancelled</p>
  )
}

function InProgress ({ isDelivery, createdDate, timeEstimation }) {
  const estimatedReadyTime = moment(createdDate)
    .add(timeEstimation, 'minutes')
    .format('HH:mm')
  if (isDelivery) {
    return (
      <p>The order is approved will deliver to destination at around {estimatedReadyTime}</p>
    )
  } else {
    return (
      <p>The order is approved and will be ready for pick up at around {estimatedReadyTime}</p>
    )
  }

  return (
    <p>No more information at the moment</p>
  )
}

function Ready ({ isDelivery, createdDate, timeEstimation }) {
  if (isDelivery) {
    return (
      <p>The order is on its way to destination</p>
    )
  } else {
    return (
      <p>The order is ready for pick up</p>
    )
  }

  return (
    <p>No more information at the moment</p>
  )
}

function PickedUpStatus ({ isDelivery, tableId }) {
  let url = `${MENUAL_URL}/menu?id=${tableId}`
  if (isDelivery) {
    url = `${url}&delivery=true`
  } else {
    url = `${url}&takeout=true`
  }
  return (
    <p>The order was picked up - <a href={url}>click here</a> to make another order</p>
  )
}

export function StatusMessage ({ order }) {
  const {
    Status,
    CreatedDate,
    TimeEstimation,
    PickedUpTime,
    TableId,
    Delivery,
    PickedUp
  } = order
  let content = null
  if (PickedUp && PickedUpTime) {
    content = (
      <PickedUpStatus
        tableId={TableId}
        isDelivery={Delivery}
      />
    )
  } else {
    switch (Status) {
      case 0:
        content = <Pending />
      break
      case 1:
        content = (
          <InProgress
            isDelivery={Delivery}
            createdDate={CreatedDate}
            timeEstimation={TimeEstimation}
          />
        )
      break
      case 2:
        content = (
          <Ready
            isDelivery={Delivery}
            createdDate={CreatedDate}
            timeEstimation={TimeEstimation}
          />
        )
      break
      case 3: 
        content = <Cancelled />
      break
      default:
        content = null
    }
  }
  return (
    <div className={style.container}>
      {content}
    </div>
  )
}
